import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const IntroContainer = styled.div`
  margin: 60px 0;
  .introWrapper {
    font-family: "Kanit", sans-serif;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 15px;
    }
    .content {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      margin-bottom: 50px;
    }
  }
  .colLeft,
  .colRight,
  .colCenter {
    filter: drop-shadow(0px 4px 46px rgba(0, 0, 0, 0.06));
    border-radius: 10px;
  }
  .imageWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .contentWrapper {
    padding: 25px 25px;
    font-family: "Kanit", sans-serif;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .title {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 10px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      margin-bottom: 10px;
    }
  }
  .colCenter {
    position: relative;
    height: 100%;
    .imageWrapper {
      height: 100%;
      border-radius: 10px;
      img {
        border-radius: 10px;
      }
    }
    .contentWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      .text,
      .title {
        color: #fff;
      }
      .text {
        display: none;
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0;
    .colCenter {
      .contentWrapper {
        .text {
          display: block;
        }
      }
    }
  }
`
const JobsContainer = styled.div`
  margin-bottom: 120px;
`
const CardWrapper = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-family: "Kanit", sans-serif;
  padding: 25px;
  .title {
    font-weight: 700;
    font-size: 24px;
    color: #181818;
    margin-bottom: 10px;
    margin-right: 35px;
  }
  .type {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: #7497b2;
    border-radius: 5px;
    position: absolute;
    top: 25px;
    right: 25px;
  }
  .body {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
    margin-bottom: 1em;
    b {
      font-weight: 400;
      color: #41a135;
    }
  }
  .apply {
    font-weight: 700;
    font-size: 16px;
    color: #7497b2;
  }
  @media only screen and (min-device-width: 1100px) and (max-device-width: 1400px) {
    .type {
      min-width: 60px;
      padding: 6px 10px;
      font-size: 12px;
      top: 35px;
      right: 0px;
    }
    }

  @media only screen and (min-width: 576px) {
    padding: 30px;
    .title {
      margin-right: 40px;
    }
    .type {
      right: 30px;
    }
  }
  @media only screen and (max-width: 320px) {
    padding: 20px;
    .title {
      margin-right: 30px;
    }
    .type {
      right: 20px;
    }
  }
`
const GalleryContainer = styled.div`
  margin-bottom: 100px;
  .galleryWrapper {
    position: relative;
    border-radius: 10px;
    img {
      filter: drop-shadow(0px 4px 46px rgba(0, 0, 0, 0.06));
      border-radius: 10px;
    }
    .contentWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .overlay {
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(196, 196, 196, 0) 48.38%,
          #000000 100%
        );
        filter: drop-shadow(0px 4px 46px rgba(0, 0, 0, 0.06));
        border-radius: 10px;
      }
      .title {
        position: absolute;
        bottom: 30px;
        left: 30px;
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: 576px) {
    .galleryWrapper {
      .contentWrapper {
        .title {
          font-size: 20px;
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .galleryWrapper {
      .contentWrapper {
        .title {
          font-size: 24px;
        }
      }
    }
  }
`

const WorkWithUsPage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, jobsPosts, jobsGallery } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    introSub,
    introTitle,
    introContent,
    introLeftImage,
    introCenterImage,
    introRightImage,
    jobSub,
    jobTitle,
    jobContent,
    gallerySub,
    galleryTitle,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)
  const getIntroLeftImage = getImage(introLeftImage)
  const getIntroCenterImage = getImage(introCenterImage)
  const getIntroRightImage = getImage(introRightImage)
  const jobs = jobsPosts.edges
  const galleryList = jobsGallery.edges

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "navMenu6" })}
      />
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{introSub}</span>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: jobTitle,
                  }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: jobContent.jobContent,
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={24} xl={8} xxl={8}>
              <div className="colCenter">
                <div className="imageWrapper">
                  <GatsbyImage image={getIntroLeftImage} alt="" />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={8} xxl={8}>
              <div className="colCenter">
                <div className="imageWrapper">
                  <GatsbyImage image={getIntroCenterImage} alt="" />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={8} xxl={8}>
              <div className="colCenter">
                <div className="imageWrapper">
                  <GatsbyImage image={getIntroRightImage} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{jobSub}</span>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: introTitle,
                  }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: introContent.introContent,
                  }}
                  style={{ marginBottom: "0" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <JobsContainer>
        <div className="gb-container">
          <Row gutter={[20, 20]}>
            {jobs &&
              jobs.map((job, index) => {
                const { slug, title, type, excerpt } = job.node
                return (
                  <Col key={index} xs={24} lg={12} xl={8}>
                    <CardWrapper>
                      <h3
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: title,
                        }}
                      />
                      <span className="type">{type}</span>
                      <div className="body">{renderRichText(excerpt, {})}</div>
                      <Link to={`/jobs/${slug}/`} className="apply">
                        {intl.formatMessage({ id: "textApply" })}
                      </Link>
                    </CardWrapper>
                  </Col>
                )
              })}
          </Row>
        </div>
      </JobsContainer>
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{gallerySub}</span>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: galleryTitle,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <GalleryContainer>
        <div className="gb-container">
          <Row gutter={[20, 20]}>
            {galleryList &&
              galleryList.map((gallery, index) => {
                const { title, image } = gallery.node
                const galleryImage = getImage(image)

                return (
                  <Col key={index} xs={24} sm={12}>
                    <div className="galleryWrapper">
                      <GatsbyImage image={galleryImage} alt="" />
                      <div className="contentWrapper">
                        <div className="overlay"></div>
                        <h4
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: title,
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                )
              })}
          </Row>
        </div>
      </GalleryContainer>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query WorkWithUsPageQuery($locale: String) {
    pageFields: allContentfulWorkWithUsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          introSub
          introTitle
          introContent {
            introContent
          }
          introLeftTitle
          introLeftText
          introLeftImage {
            gatsbyImageData(width: 1100, placeholder: BLURRED)
          }
          introCenterTitle
          introCenterText
          introCenterImage {
            gatsbyImageData(width: 1100, placeholder: BLURRED)
          }
          introRightTitle
          introRightText
          introRightImage {
            gatsbyImageData(width: 1100, placeholder: BLURRED)
          }
          jobSub
          jobTitle
          jobContent {
            jobContent
          }
          gallerySub
          galleryTitle
        }
      }
    }
    jobsPosts: allContentfulJobsPosts(
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          title
          type
          excerpt {
            raw
          }
        }
      }
    }
    jobsGallery: allContentfulJobsGallery(
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          title
          image {
            gatsbyImageData(width: 780, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default injectIntl(WorkWithUsPage)
